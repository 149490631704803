<template>
  <div>
    <div class="d-flex flex-column mt-2">
      <v-row>
        <v-col cols="12" md="9" lg="10" class="pt-0">
          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_CONVERSION_TEST_URL_FIELD"
            :value="conversion.url"
            :error-messages="errors.url"
            :placeholder="$t('main.conversion_test.do_not_forget')"
            hide-details="auto"
            :label="$t('main.conversion_test.your_url')"
            label-bold
            background-color="white"
            border-color="secondary"
            height="46"
            @focus="$emit('clear-errors')"
            @input="conversion = { ...conversion, url: $event }"
          />
        </v-col>
        <v-col cols="12" md="3" lg="2" class="pt-md-5">
          <c-btn
            block
            depressed
            height="46"
            color="primary"
            :loading="conversion.isLoading"
            :disabled="conversion.isTesting"
            :label="$t('main.conversion_test.button')"
            @click="$emit('start-test')"
          />
        </v-col>
      </v-row>
      <v-alert
        color="info"
        text
        type="info"
        icon="mdi-information-outline"
        class="mt-5 mt-md-2"
      >
        {{ $t('main.conversion_test.alert_info') }}
      </v-alert>
    </div>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'ConversionUrl',
    components: {
      CTextField,
      CBtn
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      conversion: {
        get() {
          return this.value
        },
        set(conversion) {
          this.$emit('input', conversion)
        }
      }
    }
  }
</script>
