<template>
  <v-card elevation="0">
    <v-card-title :class="['mb-4', headerIsDark ? 'text-subtitle-1 white--text black' : 'text-h6 pb-0']">
      {{ $t('main.conversion_test.conversion_test') }}
    </v-card-title>

    <v-card-text class="d-flex flex-column-reverse secondary-darken--text mt-6">
      <v-expand-transition>
        <tracker-register-conversion
          v-if="conversion.isTesting"
          class="order-sm-1"
          :conversions="conversion.count"
          @stop-conversion="stopTestConversions()"
        />
      </v-expand-transition>
      <conversion-url
        v-model="conversion"
        :errors="errors"
        class="order-sm-0"
        @clear-errors="errors = {}"
        @start-test="sendLink()"
      />
      <clickable-banner
        v-model="dialogIsShown"
        @click-banner="clickBanner()"
        @stop-test-conversion="stopTestConversions()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import trackerRepo from '@/services/repositories/tracking-repository.js'
  import ClickableBanner from './ClickableBanner.vue'
  import TrackerRegisterConversion from './TrackerRegisterConversion.vue'
  import ConversionUrl from '@/components/ConversionTest/ConversionUrl.vue'

  export default {
    name: 'ConversionTest',
    components: {
      ConversionUrl,
      ClickableBanner,
      TrackerRegisterConversion
    },
    props: {
      headerIsDark: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialogIsShown: false,
        errors: {},
        conversion: {
          isTesting: false,
          isLoading: false,
          interval: null,
          url: null,
          link: '',
          count: 0
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.conversion.interval)
    },
    methods: {
      clickBanner() {
        window.open(this.conversion.link, '_blank')
        this.dialogIsShown = false
      },
      stopTestConversions() {
        this.dialogIsShown = false
        this.conversion.isTesting = false
        clearInterval(this.conversion.interval)
      },
      async sendLink() {
        this.conversion.isLoading = true
        try {
          const { data } = await trackerRepo.getConversionLink(this.conversion.url)
          this.conversion.link = data.data.link
          this.conversion.isTesting = true
          this.dialogIsShown = true

          this.conversion.interval = setInterval(this.fetchConversion, 3000)
        } catch (error) {
          if (error.response?.data?.errors) {
            this.errors = error.response.data.errors
            return
          }
          this.$showErrorNotification(error.message)
        } finally {
          this.conversion.isLoading = false
        }
      },
      async fetchConversion() {
        try {
          const response = await trackerRepo.conversionCheck()
          this.conversion.count = response.data.data.postbacks
          if (this.conversion.count) {
            clearInterval(this.conversion.interval)
          }
        } catch (error) {
          this.$showErrorNotification(error.message)
        }
      }
    }
  }
</script>
