<template>
  <div>
    <div class="d-flex flex-column justify-center align-center secondary-lighten pt-8 pb-5 mb-2">
      <v-progress-circular
        v-if="conversions === 0"
        width="10"
        size="83"
        color="primary"
        indeterminate
      />
      <div class="text-h3 black--text font-weight-bold mt-8">
        {{ conversions }}
      </div>
      <span class="text-h6 black--text mt-1">
        {{ $t('main.conversion_test.registered_conversions') }}
      </span>
      <c-btn
        text
        class="global-cursor-pointer text-decoration-underline mt-12"
        :label="$t('main.conversion_test.stop_the_test')"
        @click="$emit('stop-conversion')"
      />
    </div>
    <v-alert color="info" text type="info" icon="mdi-information-outline">
      {{ $t('main.conversion_test.alert_registered_conversions', { button: $t('main.conversion_test.stop_the_test') }) }}
    </v-alert>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'TrackerRegisterConversion',
    components: {
      CBtn
    },
    props: {
      conversions: {
        type: Number,
        required: true
      }
    }
  }
</script>
