import http from '../http.js'

export default {
  getConversionLink(url) {
    return http.get(`/api/private/v1/conversion-link?url=${url}`, { params: { url } })
  },
  conversionCheck() {
    return http.get('/api/private/v1/conversion-check')
  }
}
