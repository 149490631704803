<template>
  <v-dialog
    v-model="isShown"
    content-class="overflow-hidden pt-4 ma-4"
    width="800"
    @keydown.esc="stopConversion()"
    @click:outside="stopConversion()"
  >
    <v-card>
      <v-card-text>
        <v-row class="flex-column flex-md-row">
          <v-col
            cols="7"
            sm="3"
            md="4"
            class="secondary-lighten global-cursor-pointer order-1 order-md-0 col-sm-5 col-7 my-0 mx-auto mt-md-4 pa-2 pa-sm-6"
            @click="$emit('click-banner')"
          >
            <v-img :src="getAdNetworkImages.test_tracking" />
            <c-btn
              block
              depressed
              class="text-subtitle-2 black white--text mt-2 text-uppercase"
              :label="$t('main.conversion_test.click_on_the_banner')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="8"
            md="7"
            class="d-flex align-start flex-column justify-center pl-sm-12 pl-3 order-2 order-md-1"
          >
            <div class="text-h6 black--text">
              {{ $t('main.conversion_test.tracking') }}
            </div>
            <ol class="pl-4 mt-3">
              <li v-for="(step, index) in bannerListStep" :key="index" class="mt-2">
                {{ step }}
              </li>
            </ol>
          </v-col>
          <v-col
            cols="1"
            class="d-flex align-start justify-center align-self-end align-self-md-auto order-0 order-md-2 pr-1"
          >
            <close-button @click="stopConversion()" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'ClickableBanner',
    components: {
      CBtn,
      CloseButton
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdNetworkImages']),
      isShown: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      bannerListStep() {
        return [
          this.$t('main.conversion_test.we_will_generate'),
          this.$t('main.conversion_test.complete_conversation'),
          this.$t('main.conversion_test.postback_settings')
        ]
      }
    },
    methods: {
      stopConversion() {
        this.$emit('stop-test-conversion')
      }
    }
  }
</script>
